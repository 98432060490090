// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
// import "./src/css/style.css"
import "./src/scss/custom.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import "./src/css/customStyle.css"

import "./static/fonts/fonts.css"
import "./src/css/navMobile.css"

export const shouldUpdateScroll = () => {
  return false
}
